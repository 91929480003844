import bannerdemo from "../images/banner_demo.jpeg";

import playButtonPoster from "../images/playButtonPoster.png";

import icareus_event_default from "../images/comingSoonThumbnail.jpg";
import channelBannerDemo from "../images/channelBannerDemo.png";
import no_data_available from "../images/DefaultImages/no_data_available.svg";
import no_channels_available from "../images/no_channels_available.svg";

import comingSoonThumbnail from "../images/comingSoonThumbnail.jpg";
import comingSoonThumbnailSerie from "../images/comingSoonThumbnailSerie.jpg";
import contactus from "../images/DefaultImages/contact_us.svg";

import organizationLogo from "../images/DefaultImages/organizationLogo.png";

import paymentMethodBambora from "../images/paymentMethods/payment-options-bambora.png";
import paymentMethodPaytrail from "../images/paymentMethods/payment-options-paytrail.png";
import paymentMethodVoucher from "../images/paymentMethods/payment-options-voucher.png";
import paymentMethodDPO from "../images/Payment/paymentMethodDPO.png";
import aboutus from "../images/DefaultImages/about_us.svg";
import pricing from "../images/pricing/DTMEDIA Pricing Plan - Annual-1.png";

import footerImage1 from "../images/footer/1.png";
import footerImage2 from "../images/footer/2.png";
import footerImage3 from "../images/footer/3.png";
import footerImage4 from "../images/footer/4.png";
import footerImage5 from "../images/footer/5.png";
import footerImage6 from "../images/footer/6.png";
import footerImage7 from "../images/footer/7.png";
import footerImage8 from "../images/footer/8.png";
import footerImage9 from "../images/footer/9.png";
import footerImage10 from "../images/footer/10.png";

const images = {
    paymentMethodBambora,
    paymentMethodVoucher,
    aboutus,
    organizationLogo,
    pricing,

    bannerdemo,

    channelBannerDemo,
    no_channels_available,
    no_data_available,
    icareus_event_default,
    playButtonPoster,

    comingSoonThumbnail,
    comingSoonThumbnailSerie,
    contactus,
    paymentMethodDPO,
    paymentMethodPaytrail,

    footerImage1,
    footerImage2,
    footerImage3,
    footerImage4,
    footerImage5,
    footerImage6,
    footerImage7,
    footerImage8,
    footerImage9,
    footerImage10,
};

const getImageByKey = (key) => {
    return images[key];
};

export { getImageByKey };
