import { useHistory } from "react-router-dom";
import { useMyContext } from "../../contexts/StateHolder";
import * as classes from "./FAQ.module.css";
import { useEffect } from "react";

const FAQEn = (props) => {
	const { language } = useMyContext();

	const history = useHistory();

	useEffect(() => {
		if (language?.includes("fi")) {
			return history.push(`/faq`);
		}
	}, [language, history]);

	return (
		<div className={`${classes.tosContainer} font-300`} style={props?.styles?.tosContaine}>
			<div className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}>
				Frequently Asked Questions (FAQ)
			</div>
			<br />
			<hr />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				How do I order and do I get an order confirmation?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The customer registers in the KoiramessuTV system and creates a personal ID there (email and
				password). After registration, the customer can select and pay for the KoiramessuTV service
				using the online store payment system. After the payment, the user returns to the service,
				which confirms that the payment was successful, and the system sends the customer an order
				confirmation. If, for one reason or another, the customer does not receive an order
				confirmation, the first thing to do is to check the Spam/Junk email box, or there has been
				an error in the email address given. The user can see their own orders and contact
				information from their own KoiramessuTV user profile. If the order does not appear in your
				profile and/or you cannot find the email confirmation, you can contact our customer service.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>How can I pay for events? </div>
			<p className={`${classes.tosMainTitle_p} `}>
				We use the Paytrail service, through which you can pay with online banking credentials or
				payment cards (credit/debit). The following payment methods are available: Osuuspankki,
				Nordea, Danske Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankkit, S-Pankki,
				Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Visa, Visa Debit, Visa Electron and
				MasterCard cards.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				The payment transaction is interrupted / the payment is not successful, what can I do?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				If the payment is not successful for some reason, you can try again. If the error message
				comes in connection with either a credit card or online bank payment, you should contact
				your bank or the company that issued the card. If payment by credit card or online bank
				payment is successful, but the order does not appear in your profile, you should contact our
				customer service.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				How long before the hourly fee must be paid so that it can be confirmed and I can see the
				event?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The payment system sends a notification to KoiramessuTV as soon as the payment is approved,
				which means you can watch the broadcast immediately after payment. In addition, you can
				watch the recording of the broadcast for a fee, so if you want to buy the right to watch the
				recording of the finished event, that is also possible.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400 `}> When does my subscription start?</div>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				Your order starts the moment your payment is approved. The order is valid for 6 months from
				the moment of payment, depending on the length of the package you choose.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Which devices can I use to watch content?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The KoiramessuTV service works in all the latest browsers on various desktop, mobile and
				tablet devices.
			</p>
			<br />
			<p className={`${classes.tosTitle} font-400`}>Does the service work with Chromecast?</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				The video player used by KoiramessuTV also supports the ChromeCast / GoogleCast feature on
				some end devices, but there are differences in end devices and we are currently unable to
				test all of them and provide support for its functionality.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				The live broadcast has started, but the video does not start on the screen, what can I do?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				If everything should be fine (and the program is running according to the schedule), but you
				can't see the video, refresh your browser. If that does not help, please try another browser
				on your device.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>The video freezes, what can I do? </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Video jittering can be caused by many reasons. Smooth viewing requires an internet
				connection of about 3 Mbps, but especially during exceptional times can have such a high use
				that the connection freezes. If available, we recommend trying another connection, either
				the mobile internet or the home Wifi connection. Restarting the browser, terminal device or
				(if using a home broadband connection, the internet modem) can also help.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				I have a problem with login/registration, what can I do?{" "}
			</div>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				If you have problems with registration/login, try another internet browser (e.g. Mozilla
				Firefox, Chrome or Windows Explorer).
			</p>{" "}
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Can I see recordings of Live broadcasts from KoiramessuTV?{" "}
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Yes you see! Live broadcasts are saved in the video library.{" "}
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				What do I do when I can't figure things out on my own?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>You can contact our customer service:</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>koulutus@kennelliitto.fi</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>We serve Mon-Fri between 8:00 and 16:00.</p>
			<br />
		</div>
	);
};

export default FAQEn;
