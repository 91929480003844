import { useHistory } from "react-router-dom";
import { useMyContext } from "../../contexts/StateHolder";
import * as classes from "./PrivacyPolicy.module.css";
import { useEffect } from "react";

import settings from "../../config_settings.json";

const PrivacyPolicy = (props) => {
	const { language } = useMyContext();
	const history = useHistory();

	useEffect(() => {
		if (language?.includes("en")) {
			return history.push(`/en/${settings.routes.privacyPolicy}`);
		}
	}, [language, history]);
	return (
		<div className={`${classes.tosContainer} font-300`}>
			<ol>
				<div
					className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
				>
					Tietosuojaseloste
				</div>
				<hr />
				<div className={`${classes.tosMainTitle_p} `}>
					{" "}
					<br />
					Koiramessu TV kunnioittaa ja suojelee verkkosivuillaan vierailevien
					henkilöiden, asiakkaidensa sekä sidosryhmätyössä toimivien henkilöiden
					yksityisyyttä. Tältä sivulta löydät tietosuojaperiaatteemme, sivujemme
					käyttöehdot ja rekisteriselosteet
				</div>
				<br />
				<br />
				<div className={`${classes.tosTitle} font-500 `}>
					Tietosuojaperiaatteemme
				</div>
				<div className={`${classes.tosMainTitle_p} `}>
					Keräämme ja käsittelemme vain tarpeellista tietoa ja poistamme
					vanhentuneet tiedot.
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Käytämme henkilötietoja viestinnässä, asiakaspalvelussa ja palveluiden
					toimittamisessa
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Henkilötietoja käsitellään keskitetysti Suomen Kennelliitto - Finska
					Kennelklubben ry.:n toimistolla Espoossa.
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Henkilötietoja käytetään myös luvallisen markkinoinnin sekä mainonnan
					kohdentamiseen
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Emme lähtökohtaisesti luovuta henkilötietoja yhdistyksen ulkopuolelle
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Tarkempaa tietoa henkilötietojen käsittelystä ja käyttämisestä sekä
					omista oikeuksistasi löydät alla olevista
					henkilötietorekisteriselosteista.
				</div>{" "}
				<br />
				<div className={`${classes.tosTitle} font-500 `}>Evästeet</div>
				<div className={`${classes.tosMainTitle_p} `}>
					Vieraillessasi Koiramessu TV nettisivuilla, tallentaa nettiselaimesi
					laitteesi kovalevylle automaattisesti evästeitä. Eväste voi sisältää
					tekstiä, numeroita, päivämääriä, paikkatietoja, ja muuta dataa mutta
					evästeisiin ei tallennu mitään henkilökohtaisia tietoja. Eväste ei
					myöskään ole sovellus, eikä sen avulla voi koneeseesi päästä viruksia
					tai muita haittaohjelmia. Evästeistä ei ole koneellesi minkäänlaista
					haittaa.
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Evästeiden avulla voidaan selvittää sivuston kävijämääriä, tallentaa
					sivustolla tehtyjä valintoja (esim. kieliasetukset), seurata kuinka
					sivustoa käytetään (ns. klikkauspolut) sekä kohdentaa ja hallita
					mainontaa (esim. ei näytetä samoja mainoksia useaan kertaan). Emme
					kuitenkaan seuraa yksittäisen kävijän tietoja, vaan tilastoimme
					kävijätietoja esim. käyttäytymisen ja maantieteellisen sijainnin
					perusteella.
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Evästeiden avulla pyrimme parantamaan käyttökokemusta sivuillamme sekä
					tuomaan sivuille mainontaa joka käyttäjää kiinnostaa. Voit esimerkiksi
					nähdä muilla sivuilla vieraillessasi Koiramessu TV:n mainontaa. Tämä
					on mahdollista evästeiden avulla.
				</div>{" "}
				<br />
				<div className={`${classes.tosMainTitle_p} `}>
					Mikäli Koiramessu TV:n sivuilla vieraileva kävijä ei halua Koiramessu
					TV:n edellä mainittuja tietoja evästeiden avulla, voi evästeiden
					käytön kieltää selaimen asetuksista.
				</div>{" "}
				<br />
				<br />
				<div className={`${classes.tosTitle} font-500 `}>
					Markkinointirekisteriseloste
				</div>
				<div className={`${classes.tosMainTitle_p} `}>
					Yhdistetty rekisteriseloste- ja informointiasiakirja
				</div>
				<div className={classes.padded}>
					<ol>
						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Rekisterinpitäjä
							</div>
							Suomen Kennelliitto - Finska Kennelklubben ry. (Y-tunnus:
							0202155-0)
							<br />
							Kamreerintie 8, 02770 Espoo <br />
							Puh. 09 887 300 <br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Yhteystiedot henkilötietojen käsittelyä koskevissa asioissa
							</div>
							Suomen Kennelliitto - Finska Kennelklubben ry. (Y-tunnus:
							0202155-0)
							<br />
							Kamreerintie 8, 02770 Espoo <br />
							Puh. 09 887 300 <br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Rekisterin nimi
							</div>
							Koiramessu TV:n asiakas-, tiedotus- ja markkinointirekisteri.{" "}
							<br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Henkilötietojen käsittelyn laillinen peruste ja tarkoitus
							</div>

							<br />

							<ul style={{ display: "inline" }}>
								Henkilötietolain 8 §:n ja EU:n tietosuoja-asetuksen 2 luvun
								mukaiset yleiset edellytykset.
								<br />
								<br />
								Henkilötietojen käsittelyn tarkoitus on viestiä Koiramessu TV:n
								palvelusta ja uusista sisällöistä.
								<br />
								<br />
								Tarvittavien henkilötietojen käsittely on välttämätön edellytys
								tarkoituksen toteuttamiseksi.
								<br />
								<br />
								Henkilötietojen käsittelyn perusteena on suostumus.
								<br />
								<br />
								Tietojen tyypillisimmät käyttöalueet ovat:
								<br />
								<br />
								<li>
									palvelujen kehittäminen, suoramarkkinointi, lahjoituspyynnöt,
									tapahtumakutsut, digimarkkinointi ja etämyynti, asiakas- ja
									mielipidetutkimukset.
								</li>
								<li>
									Koiramessu TV säilyttää vain palvelun toiminnan ja tietojen
									käyttötarkoitusten kannalta tarpeellisia tietoja, joiden
									käsittelylle on lailliset edellytykset. Tarpeettomaksi
									muuttuneet tiedot ja tiedot, joiden käsittelylle ei enää
									muutoin ole perustetta, anonymisoidaan tai hävitetään
									tietoturvallisesti.
								</li>
								<li>
									Henkilötietoja voidaan käsitellä soveltuvan lainsäädännön
									sallimin tavoin kolmansilla osapuolilla, mukaan lukien
									markkinointi, etämyynti ja markkina- sekä
									mielipidetutkimukset. Kolmannet osapuolet voivat olla
									mainostoimistoja, painotaloja tai vastaavia
									yhteistyökumppaneita, jotka tukevat rekisterin tarkoituksen
									toteutumista.
								</li>
								<li>
									Koiramessu TV:llä on asianmukaiset sopimukset tällaisten
									kolmansien tahojen kanssa henkilötietojen käsittelyä varten.
								</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Rekisterin tietosisältö{" "}
							</div>
							Rekisterissä voidaan käsitellä seuraavia tietoja:
							<br />
							<ul>
								<li>Nimitiedot</li>
								<li>Yhteystiedot (sähköposti)</li>
								<li> Osoitelähde (mitä kautta osoite on tullut rekisteriin)</li>
								<li>Yhteydenottojen historiatiedot</li>
								<li>Muut asiakkaan antamat tiedot</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Säännönmukaiset tietolähteet{" "}
							</div>
							Markkinointirekisterin henkilötietoja kerätään eri kanavien kautta
							yhteystietonsa Koiramessu TV:lle antaneista henkilöistä (esim.
							uutiskirjeen tilaus, kilpailun/ arvontakortin täyttäminen).
							<br />
							Välttämättömien tietojen antaminen on edellä mainituissa
							markkinoinnin vastaanottamisen edellytys. <br />
							<br />
							Rekisterissä ei tehdä automaattisia päivityksiä.
						</li>
						<li>
							{" "}
							<div className={`${classes.tosTitle} font-300`}>
								Säännönmukaiset tietojen luovutukset{" "}
							</div>
							Yritys ei luovuta rekisterissään olevien tietoja ulkopuolisille
							muihin kuin tässä rekisteriselosteessa mainittujen tarkoitusten
							toteuttamiseksi ja lakisääteisten tehtäviensä täyttämiseksi tai
							oikeuksiensa toteuttamiseksi ilman asianomaisen nimenomaista ja
							tietoista suostumusta.
							<br />
							<br />
							Rekisteröityjen henkilötietoja voidaan käsitellä Koiramessu TV:n
							markkinoinnin ja etämyynnin toteuttamiseen osallistuvilla
							yhteistyökumppaneilla (esim. postitusfirma) tai mahdollista
							asiakas- tai mielipidetutkimusta varten. Koiramessu TV:llä on
							asianmukaiset sopimukset tällaisten kolmansien tahojen kanssa
							henkilötietojen käsittelyä varten
							<br />
							<br />
						</li>

						<li>
							{" "}
							<div className={`${classes.tosTitle} font-300`}>
								Tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle{" "}
							</div>
							Koiramessu TV ei luovuta sidosryhmärekisterissään olevia tietoja
							EU:n tai ETA:n ulkopuolelle.
							<br />
							<br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Rekisterin suojauksen periaatteet{" "}
							</div>
							Markkinointirekisteriä ylläpidetään sähköisessä muodossa Icareus
							Suite -järjestelmässä sekä pieniltä osin verkkolevyllä
							Excel-tiedostoina. Järjestelmien tietokannat ja ohjelmistot ovat
							palveluntuottajan palvelimilla Internetissä, johon työasemilta on
							käyttäjä- ja salasanasuojauksin suojattu käyttöliittymä.
							<br />
							<br />
							Rekisterin käyttö edellyttää henkilökohtaista käyttäjätunnusta ja
							salasanaa, jonka järjestelmä pakottaa vaihtamaan määräajoin.
							Käyttöoikeus päättyy henkilön siirtyessä pois niistä tehtävistä,
							joita varten hänelle on myönnetty käyttöoikeus. Samalla lukitaan
							käyttäjätunnus.
							<br />
							<br />
							Henkilötietoja käsitellään luottamuksellisesti. Rekisterinpitäjä
							on järjestänyt tiloihinsa kulunvalvonnan.
							<br />
							<br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Tarkastusoikeus ja tietojen korjaaminen{" "}
							</div>
							<br />
							Rekisteröidyllä on oikeus tarkistaa Koiramessu TV:n rekisterissä
							olevat itseään koskevat tiedot ja pyytää virheellisten tietojen
							korjaamista. Rekisteröity voi esittää tarkastusoikeutta koskevan
							pyynnön henkilökohtaisesti tai kirjallisesti kohdassa 2
							mainittuihin yhteystietoihin. Tarkastusoikeus toteutetaan viikon
							kuluessa pyynnön esittämisestä.
							<br />
							<br />
							Rekisteriin ei sisälly tietoja, joiden osalta tarkastusoikeutta ei
							voida toteuttaa. Rekisteröity voi esittää tiedon korjaamista
							milloin tahansa rekisteriasioista vastaavalle henkilölle.
							<br />
							<br />
							Tietoja voidaan oikaista tai täydentää rekisterinpitäjän toimesta
							tai rekisteröidyn vaatimuksesta.
							<br />
							<br />
							Rekisteröidyllä on oikeus vaatia tietojen käsittelyn rajoittamista
							ja vastustaa tietojen käsittelyä.
							<br />
							<br />
							Koiramessu TV:n rekisterissä olevalla henkilöllä on oikeus kieltää
							antamiensa tietojen käytön suoramarkkinointi- tms. tarkoitukseen,
							muuttaa niiden julkaisemista koskevaa suostumustaan sekä
							muutoinkin turvautua henkilötietolaissa turvattuihin oikeuksiinsa.
							Mikäli henkilö ei toivo Koiramessu TV:ltä lainkaan postia tai
							yhteydenottoja, hän voi kieltää sen ottamalla kohdassa 2
							mainittuun osoitteeseen.
							<br />
							<br />
							Koiramessu TV: poistaa tarpeettomat henkilötiedot rekisteristä
							rekisteröidyn pyynnöstä.
							<br />
							<br />
							Rekisteröidyllä on oikeus tehdä valitus tietosuojaviranomaiselle.
							<br />
							<br />
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Tietojen säilyttäminen
							</div>
							Tietoja säilytetään toistaiseksi, niin kauan kuin ne ovat
							tarpeellisia. Tarpeettomaksi muuttuneet tiedot ja tiedot, joiden
							käsittelylle ei enää muutoin ole perustetta, hävitetään
							tietoturvallisesti.
							<br />
							<br />
						</li>
					</ol>
				</div>
			</ol>
		</div>
	);
};

export default PrivacyPolicy;
